@import (reference) 'vars';
@import '../../../../../node_modules/@unifii/library/styles/app-bar';

.uf-app-bar {
    --app-bar-background: @gray-10;
}

.uf-app-bar.default:not(.transparent):not(.accent) {
    --app-bar-background: @gray-10;
}

.uf-form-card .uf-app-bar {
    --app-bar-background: transparent;
}

uf-markdown-editor .uf-app-bar {
    --app-bar-background: transparent;
}

uf-drawer .uf-app-bar {
    --app-bar-background: transparent;
}