@import (reference) './vars.less';

::-webkit-scrollbar {
    width: @space-2;
    height: @space-4;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: @space-2;
    background-color: @gray-10;
}

::-webkit-scrollbar-button {
    height: 0px;
}

::-webkit-scrollbar-track-piece {
    background-color: @gray-5;
}

.scrollable {
    overflow-y: auto;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
    -webkit-tap-highlight-color: transparent;
}