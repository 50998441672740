@import (reference) '../vars';
@import (reference) '../layout';

.drop-indicator {
    background-color: @warning;
    height: 0;
    width: 100%;
    position: absolute;
    margin-top: 0;
    left: 0;
    z-index: 20;
    overflow: visible;

    // Triangle
    &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: -5px;

        border-style: solid;
        border-width: 5px 0 5px 7px;
        border-color: transparent transparent transparent @warning;
    }

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        top: -1px;

        background: @warning;
    }
}

uc-drag-list [dragHandle] {
    cursor: -webkit-grab;
    padding-left: @space-3;
}