@import '../../../../../node_modules/@unifii/library/styles/box';

.uf-box {
    // overwrite default border radius
    --group-border-radius: 0 !important;
}

.uf-box.draggable {
    position: relative;
    cursor: grab;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;

        border-left-style: solid;
        border-color: @gray-10;
        border-left-width: 0.25rem;
        border-left-color: @gray-10;

        transition: border-left 0.3s ease-out;
    }

    &:hover:after {
        border-color: @primary-action;
    }

}