/* You can add global styles to this file, and also import other style files */

// 3rd-party
@import 'app/styles/3rd-party/drag-drop.less';
@import 'app/styles/3rd-party/loader.less';

// Browser normalizer
@import 'app/styles/normalize.css';

// This is the main file that gets included in the app
@import 'app/styles/layout.less';

// library
@import 'app/styles/vars.less';
@import 'app/styles/app-bar.less';
@import 'app/styles/box.less';
@import 'app/styles/box-model.less';
@import 'app/styles/content.less';
@import 'app/styles/container.less';
@import 'app/styles/chips.less';
@import 'app/styles/divider.less';
@import 'app/styles/grid.less';
@import 'app/styles/inputs.less';
@import 'app/styles/buttons.less';
@import 'app/styles/actions.less';
@import 'app/styles/form.less';
@import 'app/styles/button-groups.less';
@import 'app/styles/layout.less';
@import 'app/styles/typography.less';
@import 'app/styles/cards.less';
@import 'app/styles/images.less';
@import 'app/styles/defaults.less';
@import 'app/styles/panel.less';
@import 'app/styles/repeat-form.less';
@import 'app/styles/tables.less';
@import 'app/styles/groups.less';
@import 'app/styles/lists.less';
@import 'app/styles/lozenge.less';
@import 'app/styles/external-branding.less';

// console
@import 'app/styles/table.less';
@import 'app/styles/tabs.less';
@import 'app/styles/tooltip.less';
@import 'app/styles/mixins.less';
@import 'app/styles/scroll.less';
@import 'app/styles/print.less';