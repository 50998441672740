@import (reference) '../styles/vars.less';
@import '../../../../../node_modules/@unifii/library/styles/images';

.checkboard-bg {
    background: #fff;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), rgb(255, 255, 255);
    background-clip: border-box, border-box;
    background-origin: padding-box, padding-box;
    background-repeat: repeat, repeat;
    background-position: 0 0, 10px 10px;
    background-size: 20px 20px, 20px 20px;
}

div[class*='uf-img'] {
    background-color: @uc-card-image-bg;
}

div[class*='uc-image'] {
    position: relative;
    clear: both;

    img,
    uf-icon {
        display: block;
        margin: 0 auto;

        position: relative;
        width: 100%;

        padding: @space-3;
    }

    img[src=""] {
        visibility: hidden;
    }

    uf-icon {
        height: 96px;
        width: 96px;
    }

    &[class*='--no-padding'] {
        img {
            padding: 0;
        }
    }

    &[class*='--transparent'] {
        background: none;
    }

    // fixed ratios
    &[class*='--fixed-ratio'] {
        padding-top: 100%;

        img,
        uf-icon {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            max-width: 100%;
            max-height: 100%;

            margin: auto;
        }

        img {
            width: auto;
        }

        // 16 by 9 images
        &[class*='--16by9'] {
            padding-top: 56.25%;
        }

        // 4 by 3 images
        &[class*='--4by3'] {
            padding-top: 75%;
        }

        // 3 by 2 images
        &[class*='--3by2'] {
            padding-top: 66.66%;
        }
    }

    &.right {
        float: right;
        margin-left: @gutter;
        padding: 0;
    }

    &.left {
        float: left;
        margin-right: @gutter;
        padding: 0;
    }
}