@import (reference) './vars.less';
@import '../../../../../node_modules/@unifii/library/styles/typography.less';
// @import '../../../../../node_modules/@unifii/library/styles/content.less';

//---------------------------------------
//          typography

// Mixins required for overrides

.h1-mixin() {
    font-family: @font-family-secondary;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1.75rem;
    margin: 1.5rem 0 0.75rem;
    color: inherit;
}

.h2-mixin() {
    font-family: @font-family-secondary;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: @brand-accent;
    margin: 1.5rem 0 0.75rem;
}

.h3-mixin() {
    font-family: @font-family-secondary;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: @brand-accent;
    margin: 1.5rem 0 0.75rem;
}

.h4-mixin() {
    font-family: @font-family-secondary;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.125rem;
    color: @brand-accent;
    margin: 1.5rem 0 0.75rem;
}

.h5-mixin() {
    font-family: @font-family-secondary;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1rem;
    margin: 1.5rem 0 0.75rem;
    color: @gray-80;
}

.h6-mixin() {
    font-family: @font-family-secondary;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.125rem;
    margin: 1.5rem 0 0.75rem;
    text-transform: uppercase;
    color: @gray-80;
}

.p-mixin() {
    font-size: @font-size;
    line-height: @line-height;
    margin: @gutter 0 @gutter 0;
    color: @gray-80;
}

.p-mixin--large() {
    font-size: @font-size-lg;
    line-height: @line-height-lg;
    margin: @gutter 0 @gutter 0;
    color: @gray-80;
}

.p-mixin--small() {
    font-size: @font-size-sm;
    line-height: @line-height-sm;
    margin: @space-3 0;
    color: @gray-80;
}

.ul-mixin() {
    padding-left: @gutter + (@space-2);
    margin: @gutter*.75 0;
    box-sizing: border-box;
    color: @gray-80;
}

.li-mixin() {
    box-sizing: border-box;
    padding-left: @space-3;
    font-size: @font-size;
    line-height: @line-height;

    list-style-position: outside;
}

.li-mixin--small() {
    box-sizing: border-box;
    padding-left: @space-3;
    font-size: @font-size-sm;
    line-height: @line-height-sm;

    list-style-position: outside;
}

.a-mixin() {
    font-size: inherit;
    text-decoration: underline;
    color: @link;

    cursor: pointer;

    &:hover {
        text-decoration: underline;
        color: @link-hover;
    }

    &:visited,
    &:active,
    &:focus {
        text-decoration: underline;
        color: @link-pressed;
    }
}

//---------------------------------------
//          Apply mixins

h1 {
    .h1-mixin;
}

h2 {
    .h2-mixin;
}

h3 {
    .h3-mixin;
}


h4 {
    .h4-mixin;
}


h5 {
    .h5-mixin;
}

h6 {
    .h6-mixin;
}

//---------------------------------------
//          Paragraph

p {
    .p-mixin;
}

p.small {
    .p-mixin--small;
}

p.large {
    .p-mixin--large;
}

//---------------------------------------
//          Body copy

// Experimental see if global .compact is ok, otherwise use body-copy with compact modifier
.compact,
.body-copy--compact {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin-top: @space-2;
        margin-bottom: @space-2;
    }

    .fieldset-item,
    .fieldset-item--large,
    .fieldset-item--small,
    .fieldset-item--x-small {
        margin-top: 0;
        margin-bottom: 0;
    }

}

blockquote {
    margin: 0;
    padding: @gutter;

    border-left: @space-2 solid;
    border-left-color: @brand-accent;

    >* {
        margin: 0px;
    }

}

//---------------------------------------
//          Fieldset // TODO deprecated?

.fieldset--readyonly {
    display: flex;
    flex-direction: column;
}

div[class*='fieldset-item'] {
    display: flex;
    flex-direction: row;
    margin: @space-3 0;

    >* {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

*[class*='fieldset-item']>span:first-child,
/** .fieldset-label deprecated */
.fieldset-label {
    margin-right: @space-3;

    font-family: @font-family-secondary;
    font-weight: bold;
    color: @brand-accent;
    font-size: @font-size;
    line-height: @line-height;
    word-break: break-word;
}

*[class*='fieldset-item']>span:last-child,
/** .fieldset-vale deprecated */
.fieldset-value {
    font-size: @font-size;
    line-height: @line-height;
    word-break: break-word;
}

*[class*='fieldset-item'][class*='--large'] {

    >span:first-child,
    >span:last-child,
    .fieldset-label,
    .fieldset-value {
        font-size: @font-size-lg;
        line-height: @line-height-lg;
    }
}

*[class*='fieldset-item'][class*='--small'] {

    >span:first-child,
    >span:last-child,
    .fieldset-label,
    .fieldset-value {
        font-size: @font-size-sm;
        line-height: @line-height-sm;
    }
}

*[class*='fieldset-item'][class*='--x-small'] {

    >span:first-child,
    >span:last-child,
    .fieldset-label,
    .fieldset-value {
        margin-top: @space-2;
        margin-bottom: @space-2;

        font-size: @font-size-xs;
        line-height: @line-height-xs;
    }
}

*[class*='fieldset-item'][class*='--stacked'] {
    flex-direction: column;
}

//---------------------------------------
//          Ellipsis text
.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}

//---------------------------------------
//          Media Querys

@media only screen and (min-width: 768px) {

    h1 {
        font-size: 2.5rem;
        line-height: 2.625rem;
        margin: 1.5rem 0 1.5rem;
    }

    h2 {
        font-size: 2.125rem;
        line-height: 2.25rem;
        color: @gray-80;
    }

    h5 {
        line-height: 1.125rem;
    }

}

label {
    color: @input-label;
    font-size: @font-size;
    line-height: @line-height;
}

.suffix {
    color: @gray-60;
}

label.small {
    color: @input-label;
    font-size: @font-size-sm;
    line-height: @line-height-sm;
}

label.x-small {
    color: @input-label;
    font-size: @font-size-xs;
    line-height: @line-height-xs;
}

.success-text {
    color: @success;
}

.warning-text {
    color: @warning;
}

.error-text {
    color: @error;
    font-size: @font-size-sm;
    line-height: @line-height-sm;
}

.font-size-sm {
    font-size: @font-size-sm;
    line-height: @line-height-sm;
}

/* todo: add to library temporary */

.body-copy--small,
.body-copy,
.markdown {

    // Hacky solution
    img[style*="width: 100%"],
    img[style*="width:100%"] {
        max-width: 100%;
    }

    // revert console overrides
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: @primary-action;
        text-transform: none;
    }
}