@import (reference) 'vars.less';
@import '../../../../../node_modules/@unifii/library/styles/layout.less';

// ---------------------------------------------------
// Dead simple helpers for ad-hoc flexing
// Do not add things here unless it is super generic
// Season with vanilla flex CSS for desired results
// ---------------------------------------------------

/**

    Reference

    #flex helpers

    .row;
    .col;
    .grow;
    .shrink;
    .stretch;
    .center;
    .center-all;
    .justify-center;


    #margins padding

    .padded;
    .padded--big;
    .padded--small;
    .padded--vertical;
    .padded--horizontal;
    .margin;
    .margin--vertical;
    .margin--horizontal;

*/


// @space-3: @space-3;
// @space-3-big: @gutter;
// @space-3-bigger: @gutter * 1.25;

// -----------------------------------------
//  Box -- TODO Remove
// -----------------------------------------

.box {
    display: flex;

    >* {
        .shrink;
    }

}

// -----------------------------------------
//   Box modifiers -- TODO Remove
// -----------------------------------------


.box--vertical {
    flex-direction: column;
}

.box--stretch {
    align-self: stretch;
}

// -----------------------------------------
//  Other
// -----------------------------------------

.center-items {
    display: flex;
    .center-all;
    .justify-center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 600;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.border-radius(@value) {
    -webkit-border-radius: @value;
    -moz-border-radius: @value;
    border-radius: @value;
}

// -----------------------------------------
//  Margins -- TODO Merge with better styles
// -----------------------------------------

.gap--bottom-small {
    margin-bottom: @space-2;
}

.gap--top {
    margin-top: @space-3;
}

.gap-left {
    margin-left: @space-3;
}

.gap--bottom {
    margin-bottom: @space-3;
}

.align-center {
    text-align: center;
}

.bottom {
    margin-top: auto;
}

.top {
    margin-bottom: auto;
}