@import (reference) './vars.less';
@import (reference) './mixins';

@console-background: white;
@console-nav-dev: #e0218a;
@console-nav-dev-secondary: #e64da1;
@console-nav-uat: #02e0ff;
@console-nav-uat-secondary: #35e6ff;

@font-family: 'Open Sans', sans-serif;

html {
    width: 100%;
    height: 100%;

    box-sizing: border-box;

    font-size: 1rem;
    font-family: @font-family;
    color: @gray-80;
    line-height: @line-height;

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    -ms-text-size-adjust: none;
    -webkit-user-drag: none;
    -ms-content-zooming: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;

    background-color: white;
}


[hidden] {
    display: none;
}


//---------------------------------------
//          Selection
* {
    -webkit-tap-highlight-color: transparent;
}

::selection {
    background-color: @gray-10;
}

::-moz-selection {
    background-color: @gray-10;
}

[tabindex] {
    outline-color: @gray-100;
}

//---------------------------------------
//          Safari Mobile
@supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
}

// ---------------------------------------------
// Add routes that require stretching
//
.stretch-component,
data-form-preview,
page-builder,
page {
    position: relative;
    margin: 0px;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;

    display: -ms-flexbox; // IE 10
    display: -moz-box; // Moz
    display: -webkit-flex; // Safari
    display: flex;

    box-sizing: border-box;
    overflow: auto;
}

// for panels that still need background (removed by default in 1.38 redesign)
.gray-background {
    --panel-background-colour: @gray-10;
}

.margin-auto {
    margin: auto;
    display: block;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

pre {
    overflow: auto;
    font-size: 0.9rem;
}

[hidden],
.hidden {
    display: none !important;
}

a {
    outline-color: none;
    color: @text-color;
    text-decoration: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.breakall {
    word-break: break-all;
}