// @import '../../../../../node_modules/@unifii/library/styles/groups';

// -------------------------------------------------------
//                  DEPRECATED
// -------------------------------------------------------

.uc-group {

    .uc-group-content {
        overflow: auto;

        border-right: 1px solid @gray-10;
        border-bottom: 1px solid @gray-10;
        border-left: 1px solid @gray-10;
        background: white;
    }

}

.uc-group--no-border,
.uc-group--shadow--no-border {
    .uc-group-content {
        border: 0;
    }
}

.uc-group--shadow,
.uc-group--shadow--no-border,
.uc-group--no-border--shadow {
    box-shadow: @box-shadow-lg;
}

// Required when group with shadow is used with grid
.uc-group--shadow--no-border.col-1of1,
.uc-group--no-border--shadow.col-1of1 {
    padding-left: 0;
    padding-right: 0;
    margin-right: @space-3;
    margin-left: @space-3;
    margin-bottom: @gutter;
}

*[class*='uc-group'] {

    .uc-group-content {

        overflow: auto;

        border-right: 1px solid @gray-10;
        border-bottom: 1px solid @gray-10;
        border-left: 1px solid @gray-10;

        &[class*='content--accent'] {
            border-right: 1px solid @brand-accent;
            border-bottom: 1px solid @brand-accent;
            border-left: 1px solid @brand-accent;
        }
    }

    /** Draggable Group Items **/
    &[class*='--draggable'] {
        cursor: grab;
        transition: border-left 0.3s ease-out;
        border-left: 1px solid;
        border-left-color: @gray-10;
        border-left-width: @space-2;

        &:hover {
            &:not([class*='--no-shrink']) {
                border-left-width: @gutter * 0.375;
            }

            border-left-color: @brand-accent;
        }

        &.dragging {
            cursor: grabbing;
        }

        .uc-group-content {
            border-left: none;
        }

        &[class*='--accent'] {
            border-left-color: @brand-accent;
        }
    }
}

.uf-group--no-border,
.uf-group--shadow--no-border {
    &>.uf-group-content {
        border: 0;
    }
}

.icon-warning {
    fill: @warning  !important;
}