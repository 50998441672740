.tooltip {
    display: none;
    position: fixed;
    background-color: black;
    color: white;
    font-size: 12px;
    padding: 6px;
    border-radius: 6px;
    border: 4px solid black;
    z-index: 2000;

    &.show {
        display: block;
    }
}