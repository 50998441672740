@import '../../../../../node_modules/@unifii/library/styles/lists';


*[class*='uc-list']:not([class*='uc-list-item']) {
    margin: 0;
    padding: 0;

    >*:not(:first-child) {
        margin-top: @space-2;
    }

    &[class*='--joined'] {
        >*:not(:first-child) {
            margin-top: 0;
            border-top: 0;
        }
    }
}

/** List Items **/
*[class*='uc-list-item'] {
    list-style: none;
    min-height: @app-bar-height-sm;
    padding-left: @space-3;
    padding-right: @space-3;

    /** Clickable List Items **/
    &[class*='--clickable'] {
        cursor: pointer;

        &:hover {
            background-color: @gray-5;
        }
    }

    &[class*='--border'] {
        border: 1px solid;
        border-color: @gray-10;
    }

    &[class*='--bottom-border']:not(:last-child) {
        border-bottom: 1px solid;
        border-color: @gray-10;
    }

    /** Sizes **/
    &[class*='--small'] {
        min-height: @app-bar-height-xs;
        font-size: @font-size-sm;
        line-height: @line-height-sm;
    }

    &[class*='--large'] {
        min-height: @app-bar-height;
        font-size: @font-size-lg;
        line-height: @line-height-lg;
    }
}



*[class*='uc-list']:not([class*='uc-list-item']) {

    &[class*="--gap"] {
        padding: @space-2 0 @space-2 @gutter;
    }

}

*[class*='uc-list-item'] {

    /** Draggable List Items **/
    &[class*='--draggable'] {
        position: relative;

        cursor: grab;

        transition: border-left 0.3s ease-out;
        border-left-style: solid;
        border-color: @gray-10;
        border-left-width: @space-2;
        border-left-color: @gray-10;

        &:hover {
            &:not([class*='--no-shrink']) {
                border-left-width: @gutter * 0.375;
            }

            border-left-color: @primary-action;
        }

        &.dragging {
            cursor: grabbing;
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;

            pointer-events: none;

            background: transparent;
            border-top: 1px solid @gray-10;
            border-right: 1px solid @gray-10;
            border-bottom: 1px solid @gray-10;
        }

    }

    .list-content {
        flex-grow: 1;
        flex-shrink: 1;
        padding: @space-3 0;

        h4 {
            margin: 0.875rem 0;
        }
    }

    .list-context {
        display: flex;
        float: right;
        margin-top: -@space-3;
        margin-bottom: -@space-3;
        align-items: center;

        &.left {
            float: left;

            >uf-icon {
                margin-left: 0;
            }
        }

        >uf-icon {
            margin: (2rem - @icon-size-sm)/2;
        }
    }

    .thumb {
        margin: @space-3 @space-3 @space-3 0;
        height: @gutter * 5;
        width: @gutter * 5;
    }
}

*[class*='uc-header'][class*="draggable"] {

    position: relative;
    cursor: grab;

    &:before {
        content: '';
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;

        transition: border-left-width .3s ease-in-out;
        border-left-color: @primary-action;
        border-left-style: solid;
        border-left-width: 0;
    }

    &:hover:before {
        border-left-width: @space-2;
    }

}


ul.nav-list {
    list-style: none;
    height: auto;
    display: block;
    margin: 0;
    padding: 0 @space-3;

    color: white;
    font-size: @font-size-sm;
    font-family: @font-family-secondary;

    >li {
        position: relative;
        padding: 0;
        margin: 0;

        >a {
            display: flex;
            align-items: center; // default is stretch
            min-height: 34px;
            padding: @space-2 @space-3;

            transition: all 100ms ease-in-out;
            border-radius: @border-radius;

            font-size: @font-size-sm;
            line-height: @line-height-sm;
            color: white;
            text-decoration: none;

            &:hover {
                background-color: @primary-action-hover;
            }

            >* {
                margin-left: @space-3;
            }

            &.router-link-active {
                background-color: @primary-action;
            }
        }

    }

}

// todo: having trouble with default list from library
.uc-list-item {
    margin-top: @gutter;
    padding: @space-3 @space-3 @gutter @space-3;

    border-bottom: 1px solid @gray-10;
    border-right: 1px solid @gray-10;
    border-left: 1px solid @gray-10;
    border-top: 1px solid @gray-10;

    +.uc-list-item {
        margin-top: 0;
        border-top: none;
    }
}