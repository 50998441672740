@media print {

    html,
    body,
    uc-app,
    uf-modal-container,
    uf-modal,
    uf-modal ng-component {
        position: static !important;
        overflow: visible !important;
    }

    .mask {
        display: none !important;
    }

}