uf-tabs .content {
    --tabs-header-display: initial;
    // --tab-content-height: 100%;
    --header-pane-display: initial;
    --content-pane-margin-top: initial;
}

.hide-tabs {
    --tabs-header-display: none;
    --tab-content-height: 100%;
    --header-pane-display: none;
    --content-pane-margin-top: 0;
}