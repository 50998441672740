@import (reference) './vars.less';
@import '../../../../../node_modules/@unifii/library/styles/buttons.less';


// todo: see if this makes sense to add to library
.clickable {
    cursor: pointer;
}


button {

    &.action {
        position: relative;
        height: 40px;
        width: 40px;
        bottom: 0;
        right: 0;

        box-shadow: none;

        uf-icon {
            height: @icon-size;
            width: @icon-size;
        }

    }


    &.busy {
        pointer-events: none;
        opacity: 0.2;
    }

}